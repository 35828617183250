import React, { Component } from 'react'
import PropTypes from 'prop-types' 
import { FaHorse } from 'react-icons/fa'
import * as styles from './button.module.scss'

class Button extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    animated: PropTypes.bool,
    disabled: PropTypes.bool,
    href: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.string
  }
  static defaultProps = {
    animated: true,
    color: "primary",
    size: "md",
    disabled: false
  }

  render() {
    const { children, animated, disabled, className, href, color, size, ...other } = this.props;
    var Color = color.charAt(0).toUpperCase() + color.slice(1);
    var Size = size.charAt(0).toUpperCase() + size.slice(1);
    

    return (
      <>
      {
        this.props.href ? (
          <a href={href} className={`${className} ${styles.button} ${animated ? styles.buttonAnimated : ""} ${disabled ? styles.buttonDisabled : ""} ${styles["button" + Color]} ${styles["button" + Size]}`} {...other}>
            <div>{ children }</div>
            <div className={styles.buttonAnimation}>
                <FaHorse className={`${styles.fa} ${styles.faOne}`}/>
                <FaHorse className={`${styles.fa} ${styles.faTwo}`}/>
                <FaHorse className={`${styles.fa} ${styles.faTree}`}/>
            </div>
          </a>
        ) : (
          <button className={`${className} ${styles.button} ${animated ? styles.buttonAnimated : ""} ${disabled ? styles.buttonDisabled : ""} ${styles["button" + Color]} ${styles["button" + Size]}`} {...other}>
            <div>{ children }</div>
            <div className={styles.buttonAnimation}>
                <FaHorse className={`${styles.fa} ${styles.faOne}`}/>
                <FaHorse className={`${styles.fa} ${styles.faTwo}`}/>
                <FaHorse className={`${styles.fa} ${styles.faTree}`}/>
            </div>
        </button>
        )
      }
      </>
    )
  }
}

export default Button
