import React from "react";
import { navigate } from "@reach/router";
import { handleLogin, isLoggedIn } from "../services/auth";
import { FaArrowLeft } from "react-icons/fa";
import Button from "../components/site/Button/button";
import RingLoader from "react-spinners/RingLoader";
import { gsap } from "gsap";
import Notifier from "../components/app/Notifier";
import { NotificationContainer } from "react-notifications";

class Login extends React.Component {
  state = {
    username: ``,
    password: ``,
    isLogin: false,
    success: false,
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (!this.state.isLogin) {
      this.setState({ isLogin: true });
      handleLogin(this.state)
        .then(() => {
          this.setState({ isLogin: false });
          console.log("navigate to profile");
          navigate(`/app/profile`, { replace: true });
        })
        .catch((error) => {
          Notifier.showNotification("error", "Impossible de se connecter");
          this.setState({ isLogin: false });
        });
    }
  };

  componentDidMount() {
    gsap.fromTo(this.mainDiv, { opacity: 0 }, { opacity: 1, duration: 1, ease: "Circ.easeIn" });
  }

  render() {
    if (isLoggedIn()) {
      navigate(`/app/profile`);
    }
    return (
      <div className="login" ref={(div) => (this.mainDiv = div)}>
        <NotificationContainer />
        <Button
          animated={false}
          color="white"
          size="sm"
          className="position-absolute m-5"
          href="#"
          onClick={() => navigate(-1)}
        >
          <FaArrowLeft /> Retourner au site
        </Button>
        <form method="post" onSubmit={(e) => this.handleSubmit(e)}>
          <img className="mb-4" src="/img/app_icon.png" alt="" width="72" height="72" />
          <h1 className="h3 mb-3 font-weight-normal">Connexion</h1>
          <div>
            <input
              id="username"
              type="text"
              name="username"
              placeholder="Username"
              className="form-control"
              onChange={this.handleChange}
            />
          </div>
          <div>
            <input
              id="password"
              type="password"
              name="password"
              placeholder="Mot de passe"
              className="form-control"
              onChange={this.handleChange}
            />
          </div>

          <p>
            <a href="/forgot-password">Mot de passe oublié ?</a>
          </p>

          {this.state.isLogin ? (
            <Button disabled className="mt-5" style={{ width: 184 }}>
              <RingLoader size={40} css={"display:block;margin:auto"} loading={true} />
            </Button>
          ) : (
            <Button type="submit" className="mt-5">
              Connexion
            </Button>
          )}
        </form>
      </div>
    );
  }
}
export default Login;
