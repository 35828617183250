import { isLoggedIn, getToken } from "./auth";
import { baseUriApi } from "./const";

export const post = (uri, data = null) => {
  return customFetch("POST", `${baseUriApi}${uri}`, data);
};

export const get = (uri) => {
  return customFetch("POST", `${baseUriApi}${uri}`);
};

export const customFetch = (method, uri, data = null, additionalRequestOptions = {}) =>
  new Promise((resolve, reject) => {
    const requestOptions = {
      method: method,
      headers: {
        Accept: "*/*",
        ...additionalRequestOptions.headers,
      },
      body: data ? JSON.stringify(data) : null,
      ...additionalRequestOptions,
    };

    if (!additionalRequestOptions.noContentType) {
      requestOptions.headers["Content-Type"] = "application/json-patch+json";
    }

    if (isLoggedIn()) {
      requestOptions.credentials = "include";
      requestOptions.headers.Authorization = "Bearer " + getToken();
    }

    fetch(uri, requestOptions)
      .then(async (res) => {
        if (res.ok) {
          var data = await res.text();
          if (!data) resolve({});
          var returnedData = data;
          try {
            returnedData = JSON.parse(data);
          } catch {}
          resolve(returnedData);
        } else {
          reject();
        }
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
