// extracted by mini-css-extract-plugin
export var button = "button-module--button--5TJG2";
export var buttonAnimation = "button-module--buttonAnimation--GlQyO";
export var buttonDisabled = "button-module--button-disabled--NQ0P0";
export var buttonSm = "button-module--button-sm--S31k7";
export var buttonMd = "button-module--button-md--3SahK";
export var buttonPrimary = "button-module--button-primary--4YxK5";
export var buttonWhite = "button-module--button-white--cbYhx";
export var buttonSecondary = "button-module--button-secondary--32y7N";
export var buttonAnimated = "button-module--button-animated--WaLLD";
export var fa = "button-module--fa--F1NKg";
export var faOne = "button-module--fa-one--RQZnB";
export var faTwo = "button-module--fa-two--QQpTO";
export var faThree = "button-module--fa-three--f46HM";