import PropTypes from 'prop-types';
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

const DEFAULT_TIMEOUT = 5000;

class Notifier {
    static propTypes = {
        message: PropTypes.string,
        type: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
    };


    showNotification = (type, message) => {
        if(!message) 
            message = "";
        switch (type) {
            case 'info':
                NotificationManager.info(message, 'Information', DEFAULT_TIMEOUT);
                break;
            case 'success':
                NotificationManager.success(message, 'Succès', DEFAULT_TIMEOUT);
                break;
            case 'warning':
                NotificationManager.warning(message, 'Attention', DEFAULT_TIMEOUT);
                break;
            case 'error':
                NotificationManager.error(message, 'Erreur !!', DEFAULT_TIMEOUT);
                break;
            default:
                break;
        }
    };
}




export default new Notifier();